@media screen and (min-width: 1600px) {

	section.home li.slide-1 {
		height: 900px
	}
	section.home li.slide-2 {
		height: 500px
	}
	section.home li.slide-2 {
		height: 700px
	}
	
}
@media screen and (max-width: 1200px) {

	.eat-menu-column-1,
	.eat-menu-column-2,
	.eat-menu-column-3 {
		width: 33%;
	}
	.eat-menu-column-4 {
		width: 100%;
	}



	.mapDialog {
		bottom: 75px;

		.content-wrapper {
			flex-direction: column-reverse;
			.left-column,
			.right-column {
				width: 100%;
			}
			.left-column {
				.add-back {
					display: inline-block;
					padding: 0;
					margin: 30px 15px 0 0;
					float: right;

					div:nth-child(1) {
						display: none;
					}
				}
			}
			.right-column {
				.stage-thumbs {
					flex-wrap: nowrap;
					height: auto;
					padding: 0;
					overflow-x: auto;
					div {
						flex: 0 0 auto;
					}
				}
			}
		}
	}







	.panel-maps .map-panel-items li .wa-click-to-close {
		font-size: 12px !important;
	}



	.container {
		width: 980px;
	}

		.card {
		min-height: 525px;
	}


	header .mainnav ul {
		margin: 0 0 0 5px;

		li {
			margin-right: 20px;
		}

		li:last-child {
			margin-left: 10px;
		}
	}



	footer {

		font-size: 11px !important;
		p.lower {
			font-size: 11px !important;
		}

		.footer-social-list {
			li { padding-right: 5px; }
		}


		.mobile-footer-legal {
			text-align: center;
		}
		
	}

	.grid-container .grid-item {
		height: 425px;
	}

	section.hero.single {
		min-height: 450px;
	}
	section.home li.slide-1 {
		height: 700px;
	}
	.map-marker-image {
		width: 325px;
		height: 325px;
	}
	.svg-map-container {
		 width: 100%;
		 position: relative;
		 margin: 0 auto;

	}

	.inspiration-slider {
		.slick-prev, 
		.slick-next {
			top: 50%;
		}
		.slick-slide {
			height: 536px;
		}
	}


}










@media screen and(max-width:992px) {

	.eat-menu-column-1,
	.eat-menu-column-2,
	.eat-menu-column-3 {	
		width: 50%;
		float:left;
	}

	.eat-menu-column-3 {	
		float: none;
	}
	.eat-menu-column-4 {
		width: 100%;
		float: none;
	}

	.panel-maps .map-panel-items li .child-panel.eat .content .whenBtn	{
		min-width: 110px;
		margin-right: 0;
	}	
	.container {
		width: 768px;
	}

	.result-thumb {
		display: none;
	}

	header {
		padding: 0 20px;
	}

	header .mainnav ul {
		font-size: 14px;
	}


	header .mainnav ul {
		input[type=text] {
			display: none;
		}
		.mobileSearchForm {
			position: absolute;
			top: 39px;
			width: 248px;
			right: -5px;

			z-index: 9;
			background: #240f0e;
			padding: 10px;

			form {

				.mobile-form-error {
					position: absolute;
					padding: 1px 7px;
					top: -25px;
					left : 10px;
					background: #e04749;
					color: #fff;
					p {
						margin: 0;
					}					
				}
				input[type=text] {
					margin-right: 3px;
					display: inline;
					vertical-align: top;
					width: 200px;
					padding: 3px;
					background: rgba(255,255,255,0.25);
					border: 0;
					color: #fff;
					text-indent: 5px;
					&:focus {
						outline: none;
					}
				}
				.fa-search {
					color: #fff;
					font-size: 1.5em;
					cursor: pointer;
					vertical-align: middle;
				}
			}
		}




	}

	section.home li.slide-1 {
		height: 475px;

		> img {
			width: 350px;
		}
	}

	section.home li.slide-1 p,
	section.home li.slide-2 p {
		font-size: 1.5rem;
	}

	section.home li.slide-2 img {
		width: 80px;
	}
	section.home li.slide-3 img {
		width: 475px;
	}

	section.hero.single {
		min-height: 400px;
	}
	.map-marker-image {
		width: 300px;
		height: 300px;
	}


	.map-left-panel {
		width: 275px;
	}


	.map-left-panel .results-list ul li .result-name {
		width: calc(100% - 100px);
	}


	#dMap {
		width: calc(100% - 275px);
	}


	.card {
		min-height: 500px;
	}



	.map-overlay div h3 {
		font-size: 20px;
		margin-bottom: 10px;
	}
	.map-overlay div.customize p,
	.map-overlay div.discover p {
		line-height: 150%;
	}

	div.discover {
		li {
			div {
				height: 75px;
				width: 75px;
				background-image: url(/img/gold-circle.png);
				background-repeat: no-repeat;
				background-size: cover;
			}
		}
	}




	footer {

		.mobile-footer-legal {
			text-align: center;
		}
		.mobile-footer-social {
			display: none;
		}

	}

		.footer-newsletter,
	.footer-related-sites,
	.footer-connect-with-us {
		text-align: center;
	}




	#Layer_1 {
		width: 100%;
	}


	.inspiration-slider {
		.slick-prev, 
		.slick-next {
			top: 50%;
		}
		.slick-slide {
			height: 417px;
		}
	}

	.neighborhood-description {
		a.button {
			padding: 10px 20px;
		}
	}
	.map-marker-image {
		width: 275px;
		height: 275px;
	}


	.panel-maps .map-panel-items li .wa-click-to-close {
		p {
			line-height: 100%;
		}
		img {
			width: 55px;
		}
	}



	.mapDialog {
		left: 275px;
	}








}



@media screen and(max-width: 768px) {

	.tourDialog,
	.tourOverviewDialog {

		top: 84px;
		width: 375px;
		height: calc(100% - 159px);
		transform: translateX(-150px);
		&.active {
			transform: translateX(275px);
		}
	}

	.container { width: 725px !important }

	.main-container { padding-top: 85px; }

	header { height: 85px; }

	header .logo {
		width: 115px;
		height: 69px;
		margin-top: 8px;
		img {
			width: 100%;
		}
	}
	header .mainnav {
		font-size: 12px;
		height: 85px;

		>div {
			height: 57px;
		}

		ul {
			margin: 0 0 0 30px;
			li {
				margin-right: 15px;
			}
			.mobileSearchForm {
				top: 24px;
			}
		}
	}
	
	header .mynola div.foo span {
		font-size: 32px;
	}
	header .mynola div.foo svg {
		height: 32px;
		width: 32px;
	}
	header .mynola div.moo {
		font-size: 12px;
		margin-top: -5px;
	}

	.panel-neighborhood,
	.panel-inspiration,
	.panel-maps {
		&.active {
			top: 84px;
		}
	}
	.panel-maps .map-panel-items li .child-panel.active {
		top: 179px;
	}


	.map-overlay {
		width: 768px;
	}



	.map-overlay div h3 {
		font-size: 20px;
		margin-bottom: 10px;
	}
	.map-overlay div.customize p,
	.map-overlay div.discover p {
		line-height: 150%;
	}

	div.discover {
		li {
			div {
				height: 75px;
				width: 75px;
				background-image: url(/img/gold-circle.png);
				background-repeat: no-repeat;
				background-size: cover;
			}
		}
	}
	.footer-newsletter,
	.footer-related-sites,
	.footer-connect-with-us {
		text-align: center;
	}

	.mapDialog {
		top: 85px;
	}
}