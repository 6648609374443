.map-marker-image {
	border-radius: 50%;
	height: 379px;
	width: 379px;
}


.sub-card {
	padding: 20px 35px;
	text-align: center;

	.icon {
		border-bottom: 2px solid #cdc7c6;
		padding-bottom: 25px;
		min-height: 95px;
		img {
			width: 50px;
			height: 50px;
		}
	}
	.title {
		margin-top: -35px;

		h2 {
			display: inline-block;
			background: #f6f1eb;
			font-weight: bold;
			padding: 0 20px;
			letter-spacing: 1px;
		}

		.content {
			text-align: justify;
		}
	}
}


.lightbox {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 20;
	background: rgba(0,0,0,0.75);

	&.active {
		display: block;
	}
	.content {
		width: 750px;
		margin: 125px auto;
		box-shadow: 0 0 25px rgba(0,0,0,0.75);
		img { width: 100%; }

	}
}