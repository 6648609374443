@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "button.scss";
@import "panels.scss";
@import "font.scss";
@import "map.scss";
@import "icons.scss";
@import "slider.scss";
@import "neighborhoods.scss";


[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}



.add-context {
	width: 100%;
	margin-top: 5px;
	padding: 0 5px;
	background: #eee;
	p {
		margin: 0;
		text-align: right;
	} 
}


/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #3c7dd1;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3c7dd1;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slide-44 {

    width: 100%;
    height: 400px;
    position: relative;

    .homepage-hero-module {
        height: 100%;
        width: 100%;
        border-right: none;
        border-left: none;
        position: relative;
    }
    .no-video .video-container video,
    .touch .video-container video {
        display: none;
    }
    .no-video .video-container .poster,
    .touch .video-container .poster {
        display: block !important;
    }
    .video-container {
        position: relative;
        bottom: 0%;
        left: 0%;
        height: 100%;
        width: 100%;
        overflow: hidden;
        background: #000;
    }
    .video-container .video-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index:25;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items:center;
        div {
            text-align:center;
        }
    }
    .video-container .poster img {
        width: 100%;
        bottom: 0;
        position: absolute;
    }
    .video-container .filter {
        z-index: 100;
        position: absolute;
        background: rgba(0, 0, 0, 0.4);
        width: 100%;
    }
    .video-container video {
        position: absolute;
        z-index: 0;
        bottom: 0;
    }
    .video-container video.fillWidth {
        width: 100%;
    }
}


.main-container {
	padding-top: 100px;
	margin: 0 auto;
}








a:focus {
	outline: none !important;
}

input::-moz-focus-inner {
  border: 0;
}

body,html {
	//max-height: 100%;
	width: 100%;
}

html.lock {
	height: 100%;
	overflow: hidden;

	body {
		height: 100%;
		overflow: hidden;
	}
}

.toast-top-right {
	top: 125px;
	right: 25px;
}

.toast-blue {
	background: #e98d28;
}

.info {
	background: #d9edf7;
	padding: 10px;
	margin-bottom: 10px;
	p {
		margin: 0;
	}
}

.fa-trash, .fa-edit, .fa-close {
	cursor: pointer;
}





.new-list-category {
	height: 43px;
}

.sticky {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
}






.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	margin-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


body {
	font-family: 'proximalight';
	font-size: 14px;
	background: #f6f1eb;
}


.neighborhoodDialog {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 12;
	background: rgba(0,0,0,0.4);

	.content {
		width: 500px;
		background: #fff;
		position: relative;

		.closeBtn {
			position: absolute;
			cursor: pointer;
			top: 0;
			right: -45px;
			height: 35px;
			width: 35px;
			border: 2px solid #fff;
			border-radius: 50%;
			text-align: center;
			color: #fff;
			font-size: 2.25rem;
		}
		.heading {
			height: 225px;
			overflow: hidden;
			img {
				max-width: 125%;
				margin-left: -13%;
			}
		}
		.body {
			text-align: center;
			padding: 10px 25px;
			h3 { margin-top: 10px; }
			p { margin-bottom: 20px; }
		}
		.footer {
			margin-top: 20px;

			ul {
				li { cursor: pointer; }
				p {
					padding: 10px 0;
					margin: 0;
					font-weight: bold;
				}
			}
		}
	}

}


.map-overlay {
	position: fixed;
	display: flex;
	justify-content: center;
	top: 20%;
	left: 0;
	z-index: 5;
	background: rgba(0,0,0,0.7);
	width: 100%;
	height: 520px;

	div {
		color: #fff;
		text-align: center;
		width: 50%;
		transition: 0.3s ease all;

		h3 {
			display: inline-block;
			//padding-bottom: 5px;
			margin-bottom: 25px;
			border-bottom: 1px solid rgba(255,255,255,0.4);
		}

		img.foo {
			transition: all 650ms cubic-bezier(0.680, -0.550, 0.265, 1.550); /* easeInOutBack */
		}


		&.customize {
			color: #fff;
			text-align: center;
			width: 50%;
			padding: 15px;
			transition: 0.3s ease all;
			h3 { text-transform: uppercase; }
			p { line-height: 200%; }
			a { color: #fff; }
			&:hover {
				cursor: pointer;
				background: rgba(0,0,0,0.5);

				a {
					color: orange;
				}
				img.foo {
					cursor: pointer;
					transform: rotate(30deg);
				}

			}
		}
		&.discover {
			padding: 15px;
			p { line-height: 200%; }
			h3 { text-transform: uppercase; }

			&:hover {
				cursor: pointer;
				background: rgba(0,0,0,0.5);

				a {
					color: orange;
				}

			}
		}

		li {
			padding: 0 15px;
			div {
				width: 90px;
				height: 90px;
				margin-bottom: 10px;
				background: url(/img/gold-circle.png) no-repeat center;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 0.3s ease all;
				border-radius: 50%;
				img { transition: 0.5s ease all; }
				&:hover {
					background: #c79521;
					img {
						transform: rotateY(180deg);
					}
				}

			}
		}
	}
}


.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}




.spinner {
	width: 40px;
	height: 40px;
	background-color: #333;
	background-color: #240f0e;
	margin: 100px auto;
	-webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
	animation: sk-rotateplane 1.2s infinite ease-in-out;
}
@-webkit-keyframes sk-rotateplane {
	0% { -webkit-transform: perspective(120px) }
	50% { -webkit-transform: perspective(120px) rotateY(180deg) }
	100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}
@keyframes sk-rotateplane {
	0% {
		transform: perspective(120px) rotateX(0deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
	} 50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
	} 100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	}
}


.spinner2 {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner2 > div {
  width: 18px;
  height: 18px;
  background-color: #fff;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner2 .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner2 .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}







.spinner3 {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner3 > div {
  width: 18px;
  height: 18px;
  background-color: #2d2d2d;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner3 .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner3 .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}








.card {
	min-height: 565px;
	width: 32%;
	margin: 8px .5% 8px .5%;
	background: #fff;
	border-bottom: 2px solid #c8c4c1;
	text-align: center;
	position: relative;

	.card-image {
		position: relative;
		img {
			width: 100%;
			&.faded {
				opacity: .4;
			}
		}
	}
	.card-listing-count {
		border-radius: 50%;
		position: absolute;
		left: 20px;
		bottom: 40px;
		height: 85px;
		width: 85px;
		background: #e98d28;
		&.dark { background: #2d2d2d; }
		color: white;
		padding-top: 15px;
		p {
			margin: 0;
			line-height: 125%;
			text-transform: uppercase;
			font-size: 10px;
		}
		.count {
			font-family: 'Open Sans';
			font-size: 3rem;
			font-weight: 300;
			letter-spacing: -1px;

		}
	}
	.card-content {
		padding: 25px 22px;
		min-height: 175px;
		h4 {
			font-family: 'hannahnarrow';
			font-size: 3.25rem;
			color: #e98d28;
		}
	}
	.cta {
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 10px;

		//margin-bottom: 25px;
		.btn { width: 65%; }
	}
}




.neighborhood-description {
	display: flex;
	align-items: center;
	margin-top: -150px;
	margin-bottom: 75px;

	img {
		transition: 0.3s ease all;
		transition-delay: 1s;
		opacity: 0;
		&.foo {
			opacity: 1;
		}
	}
}

#map {
	height: 700px;
	width: 100%;
}

.angular-google-map-container { height: 800px; }
.marker_labels {
	color: #fff;

}


.close {
	margin: 15px;
	font-size: 24px;
	color: #000;
	opacity: .6;
}

.loginDialog,
.registerDialog,
.logoutDialog,
.forgotDialog,
.termsDialog,
.privacyDialog,
.copyrightDialog,
.passwordResetDialog,
.handleDialog,
.newCategoryDialog,
.editCategoryDialog {

	transition: 0.3s ease all;
	display: none;
	transform: scale(0);

	&.active {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		z-index: 10;
		background: rgba(0,0,0,0.5);
		position: fixed;
		color: #4d4d4d;
		//width: 100%;
		//height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 20;
		transform: scale(1);
	}

	.content {
		float: left;
		width: 425px;
		background: #f9f8f6;
		.errors {
			margin-top: -25px;
			margin-bottom: 25px;
			background: #e04749;
			color: #fff;
			padding: 10px;
			p {
				margin: 0;
				padding: 0;
			}
		}
		.header,
		.body {
			width: 65%;
			margin: 0 auto;
			.error {
				border: 1px solid #e04749;
				background: rgba(224,71,73,0.2);
			}
		}
		.header {
			padding-top: 25px;
			margin-bottom: 35px;
			hr {
				margin-top: 0;
				margin-bottom: 10px;
				border-top: 1px solid rgba(0,0,0,0.1);
			}
		}
		.body {
			img { cursor: pointer; }
		}
		.footer {
			padding: 20px 0;
			background: #f0ebe5;
			p { margin: 0; }
		}
	}
}


select {
	-webkit-appearance: none;
}

.form-control,
.btn,
.well {
	border-radius: 0;
}
.form-control {
	padding: 12px;
	height: 45px;
	font-size: 16px;
}
.btn {
	padding: 10px 35px;
	text-transform: uppercase;
}

.npanel {
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: space-between;
	div {
		box-sizing: border-box;
		padding: 10px;
		width: calc(100% / 6);
		height: 150px;
		border: 3px solid #000;
		display: flex;
		align-content: center;
		align-items: center;

		p {
			width: 100%;
		}
	}
	div:first-child,
	div:last-child {
		width: calc(100% / 6 / 2);
	}
	div:hover {
		cursor: pointer;
	}
}


header {
	background: #240f0e;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 501;
	height: 100px;


	.logo,
	.mainnav,
	.search {
		float: left;
		height: 100px;

	}

	.logo {
		width: 150px;
	}
	.mainnav {
		padding-top: 58px;
		font-size: 15px;
		font-family: 'proximaregular';

		ul {
			margin: 0 0 0 25px;
			padding: 0;
			list-style: none;
			li {
				padding: 0 0 5px 0;
				display: inline;
				margin-right: 25px;
			}
			li:hover, li.active {
				border-bottom: 2px solid #e98d28;
			}
			li:last-child {
				margin-left: 115px;
				border-bottom: none;
			}
			input[type=text] {
				border: none;
				text-indent: 2px;
				background: rgba(255,255,255,0.4);
				padding: 2px 5px;
				color: #fff;
				width: 175px;
				outline: none;
			}
			.fa-search,
			.fa-close {
				cursor: pointer;
				color: white;
				font-size: 1.25em;
				vertical-align: middle;
			}
			a {
				color: #fff;
				padding: 0 0 22px 0;
				outline: none;
			}
			a:hover, a.active, a:focus {
				text-decoration: none;
				outline: none;
			}
		}
	}

	.search {}

	.mynola {
		margin-top: 15px;
		float: right;
		color: #fff;


		div.foo {

			cursor: pointer;

			span {
				font-family: 'intheknow';
				font-size: 42px;
				transition: 0.3s ease all;
			}
			svg {
				width: 42px;
				height: 42px;

				path {
					transition: 0.3s ease all;
					fill: #fff;
				}
			}
			&:hover {

				span {
					color: #b8854e;
				}
				path {
					fill: #b8854e;
				}
			}
		}
		div.moo {
			margin-top: -10px;
			text-align: center;
			span {
				width: 100%;
				font-size: 14px;
				font-family: 'proximaregular';
				a {
					color: #fff;
				}
			}
		}
	}
}

/*
.headroom { transition: transform 200ms linear; }
.headroom--pinned { transform: translateY(0%); }
.headroom--unpinned { transform: translateY(-100%); }
*/
.arrow-up {
	width: 0;
	height: 0;
	border-left: 22px solid transparent;
	border-right: 22px solid transparent;
	border-bottom: 25px solid white;
	position: absolute;
	top: -25px;
	left: calc(50% - 22px);
}
.arrow-down {
	width: 0;
	height: 0;
	border-left: 22px solid transparent;
	border-right: 22px solid transparent;
	border-top: 25px solid white;
	position: absolute;
	bottom: -25px;
	left: calc(50% - 22px);
}

.grid-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;

	.grid-item {
		width: 32%;
		background: #fff;
		margin: 0 .5% 15px .5%;
		text-align: center;
		cursor: pointer;
		transition: 0.3s ease all;
		position: relative;
		height: 466px;

		&:hover {
			.grid-item-content {
				padding-bottom: 50px;
			}
		}

		img { width: 100%; }

		.grid-item-content {
			transition: 0.3s ease all;
			padding: 20px;
			width: 100%;
			background: #fff;

			.list-category {
				font-family: 'hannahnarrow';
				color: #eab74a;
				font-size: 2.75rem;
				margin: 0;
			}
			h3 {
				margin-top: 10px;
				font-family: 'Open Sans';
				display: inline-block;
				//border-bottom: 1px solid;
				font-weight: bold;
				text-decoration: underline
			}
			a { color: #2d2d2d; }
			a:hover { text-decoration: none; }
		}
	}
}




aside.panel {
	border-radius: 0;
	position: absolute;
	width: 400px;
	top: 150px;
	left: 0;
	height: 600px;
	overflow: scroll;
	img {
		width: 100%;
	}
	h3 { margin: 0; }

	li { transition: 0.3s ease all; }

	li:nth-child(even) {
		background: #eee;
	}
	li:hover {
		color: #fff;
		background: #337ab7;
	}
}






section {
	&.home {
		color: #fff;
		ul { margin: 0; padding: 0; }

		li {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			&.slide-1 {
				height: 750px;
				position: relative;
				background: url(/img/home-slide-1-mgindian.jpg) no-repeat center;
				background-size: cover;
				img {
					margin-top: 100px;
				}
				p {
					//margin-top: 25px;
					font-size: 1.75rem;
					text-shadow: 0 0 10px black;
				}
			}
			&.slide-2 {
				font-family: 'proximalight';
				font-size: 2rem;
				height: 410px;
				background: url(/img/slide-2-background.jpg) no-repeat center;
				background-size: cover;
				p {
					font-size: 1.75rem;
					text-align: center;
				}
				ul { margin: 25px 0; }
				li {
					font-size: 1.5rem;
					font-family: 'proximaregular';
					display: inline-block;
					text-align: center;
					padding: 0 50px;

				}
				li span {
					margin-top: 20px;
					display: block;
				}
				li img {
					transition: 0.5s ease all;
				}
				li:hover img {
					transform: rotateY(180deg);
				}
				a {
					color: #fff;
					&:hover {
						text-decoration: none;
					}
				}


			}
			&.slide-3 {
				height: 635px;
				background: url(/img/home-slide-3.jpg) no-repeat center;
				background-size: cover;
				font-size: 1.75rem;
			}
			&.slide-4 {
				height: 635px;
				//background: url(/img/home-slide-4.jpg) no-repeat center;
				background: url(/img/home-slide-4-new.jpg) no-repeat center;
				background-size: cover;
				font-size: 1.75rem;
			}
			&.slide-5 {
				background: #f6f1eb;
				text-align: center;
				color: #2d2d2d;
			}
		}
	}
	&.hero {
		color: #fff;
		text-align: center;
		min-height: 500px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		&.fourofour {
			background: url(/img/get-inspired-banner.jpg);
			background-size: cover;
		}

		&.single {
			text-align: left;
			align-items: stretch;
		}


		h2 {
			font-family: 'intheknow';
			font-size: 7.5rem;
			letter-spacing: 1px;
		}
		p {
			font-size: 1.5rem;
		}


		&.neighborhoods {
			min-height: 250px;
			background: url(/img/neighborhoods/banners/overview.jpg) no-repeat center;
			background-size: cover;
		}
		&.inspiration {
			//background: url(/img/inspiration-landing.jpg) no-repeat center;
			background: url(/img/get-inspired-banner.jpg) no-repeat center;
			background-size: cover;
		}
	}
}



footer {
	background: #3a3a3a;
	padding: 50px 25px;
	color: #fff;

	ul { margin: 0; padding: 0; }
	.list-inline > li {
		padding-right: 15px;
		&:last-child {
			padding-right: 0;
		}
	}
	a {
		color: #fff;
		cursor: pointer;

		&:hover {
			color: #e98d28;
			text-decoration: none;
		}
	}

	form {
		margin-bottom: 20px;
		input[type=text] {
			padding: 5px 10px;
			margin: 0 5px 0 0;
			width: 200px;
			background: rgba(255,255,255,0.2);
			color: #2d2d2d;
			transition: 0.3s ease all;
			border: 0;
			&:focus, &.ng-not-empty {
				background: rgba(255,255,255,0.75);
			}
		}
	}
	p {
		text-transform: uppercase;
		font-size: 1.2rem;
		color: #ffffff;
		&.lower {
			text-transform: none;
			font-size: 1.3rem;
			a { color: #336699; }
		}
	}
	.mobile-footer-legal {
		text-align: center;
	}
}


.footer-newsletter {

}
.footer-related-sites {
	text-align: center;
}

.footer-connect-with-us {
	text-align: right;
	svg:hover {
		path {
			fill: #e98d28;
		}

	}
}


.non-map-related-sites {
	li.gonola {
		width: 57px;
		height: 43px;
		margin-right: 15px;
		margin-top: 10px;
		background-image: url(/img/gonola-footer-sprite-large.png);
		background-repeat: no-repeat;
		background-position: 0px 0px;
		cursor: pointer;
		transition: none;
		&:hover {
			background-position: -57px 0px;
		}
	}
	li.neworleansonline {
		width: 94px;
		height: 54px;
		margin-right: 0;
		margin-top: 0;
		cursor: pointer;
		background-image: url(/img/neworleans-og-sprite.png);
		background-repeat: no-repeat;
		background-position: 0px 0px;
		transition: none;
		&:hover {
			background-position: -94px 0px;
		}
	}
}

.footer-social-list {
	li {
		cursor: pointer;
		position: relative;
		transition: 0.3s ease all;
		a { cursor: pointer; }
		&:hover {
			path {
				fill: orange;
			}
			.footer-tooltip {
				display: block;
			}
		}

		&.gonola-listitem {
			width: 40px;
			height: 40px;
			margin-right: 15px;
			margin-top: 10px;
			background-image: url(/img/gonola-footer-sprite.png);
			background-repeat: no-repeat;
			background-position: 0px 0px;
			transition: none;
			&:hover {
				background-position: -40px 0px;
			}
		}
		&.neworleansonline-listitem {
			width: 94px;
			height: 54px;
			margin-right: 0;
			margin-top: 0;
			background-image: url(/img/neworleans-og-sprite.png);
			background-repeat: no-repeat;
			background-position: 0px 0px;
			transition: none;
			&:hover {
				background-position: -94px 0px;
			}
		}

		.footer-tooltip {
			display: none;
			position: absolute;
			padding: 3px;
			background: #fff;
			color: #2d2d2d;
			font-size: 11px;
			top: -25px;
			text-align: center;
			font-family: 'proximabold';
			&.twitter {
				top: -33px;
				left: -60px;
				width: 150px;
			}
			&.facebook {
				top: -33px;
				left: -57px;
				width: 150px;
			}
			&.pinterest {
				top: -33px;
				left: -59px;
				width: 150px;
			}
			&.instagram {
				top: -33px;
				left: -64px;
				width: 160px;
			}
			&.gonola {
				top: -50px;
				left: -32px;
				width: 110px;

			}
			&.neworleansonline {
				top: -41px;
				left: -30px;
				width: 150px;
			}
			.triangle {
				display: block !important;
				position: absolute;
				bottom: -9px;
				right: -6px;
				width: 0;
				height: 0;
				border: 0 solid transparent;
				border-left-width: 10px;
				border-right-width: 10px;
				border-top: 10px solid #ffffff;
			}
		}

	}


}


.form-error {
	position: absolute;
	padding: 5px 10px;
	top:-5px;
	left : 15px;
	background: #e04749;
	min-width: 200px;
	&.map-view {
		top: -35px;
	}
	p {
		color: #fff;
		font-weight: bold;
		margin: 0;
		font-size: 1rem;
		text-align: center;
	}
	&.login {
		width: 65%;
		top: -30px;
	}
}
.arrow-down-error {
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid #e04749;
	position: absolute;
	bottom: -10px;
	left: calc(50% - 22px);

}



.neighborhood-dialog-icon-list {
	margin: 0;
	padding: 10px;
	list-style: none;
	text-align: center;

	li {
		float: left;
		width: 20%;
		padding-top: 30px;
		padding-bottom: 10px;
		transition: 0.3s ease all;

		&:hover {
			color: #df8731;
		}

		&.stay {
			background: url(/img/icon-stay.png) no-repeat top center;
			&:hover {

				background: url(/img/icon-stay-orange.png) no-repeat top center;
			}
		}
		&.eat {
			background: url(/img/icon-eat-black.png) no-repeat center 5px;
			&:hover {
				background: url(/img/icon-eat-orange.png) no-repeat center 5px;
			}
		}
		&.drink {
			background: url(/img/icon-drink.png) no-repeat center 0px;
			&:hover {
				background: url(/img/icon-drink-orange.png) no-repeat center 0px;
			}
		}
		&.attractions {
			background: url(/img/icon-seedo.png) no-repeat center 0px;
			&:hover {
				background: url(/img/icon-seedo-orange.png) no-repeat center 0px;
			}
		}
		&.shopping {
			background: url(/img/icon-shopping.png) no-repeat center 0px;
			&:hover {
				background: url(/img/icon-shopping-orange.png) no-repeat center 0px;
			}
		}
	}
}




.svg-map-heading {
	padding: 25px 0;
	position: absolute;
	width: 100%;
	text-align: center;
	//h2 { font-weight: 300; font-family: 'proximabold';}
	h2 { font-weight: 300; font-family: 'intheknow';font-size:7.5rem;}
}




.get-inspired-heading {
	padding: 20px;
	h2 { font-family: 'proximabold'; }
}



.inspiration-feed {
	.slick-slide {
		height: 642px;
	}
}





.slick-prev,
.slick-next {
	background-color: none;

}
.videoTitle {
	display: block;
	width: 100%;
	text-align: center;
	position: absolute;
	z-index: 50;
	top: 0;
	padding-top: 125px;
	transition: 0.5s ease all;

	&.dismiss {
		transform: translateY(-50%);
		opacity: 0;
	}
	h2 {
		font-size: 4rem;
		text-shadow: 0 0 20px rgba(0,0,0,0.99);
	}
	img {

		margin: 100px auto;
		width: 72px;
	}
}

.shadow {
	text-shadow: 1px 1px 10px rgb(0,0,0);
}

.hannahnarrow {
	font-family: 'hannahnarrow';
}

tr.closed {
	background: #eee;
}




.add-custom-location-modal,
.edit-custom-location-modal {
	display: none;
	position: fixed;
	top: 100px;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 100;
	background: rgba(0,0,0,0.75);
	&.active {
		display: block;
	}
	.content {
		padding: 25px;
		width: 75%;
		height: 85%;
		margin: 25px auto;
		background: #fff;
		overflow: scroll;
	}
}


#addLocationMap,
#editLocationMap,
#addContextMap {
	width: 100%;
	height: 350px;
	margin-bottom: 25px;
}

.drop-box {
	width: 100%;
	min-height: 150px;
	background: url(/img/drop-image.png) no-repeat center center;
	border: 1px dashed #bbb;
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}


.drop-box-custom-location {
	margin: 5px;
	background: #eee;
	padding-left: 5px;
	cursor: pointer;

}


.customLocationBanners {
	display: flex;
	flex-wrap: wrap;

	.customLocationBanner {
		width: calc(50% - 10px);
		//height: 200px;
		overflow: hidden;
		margin: 5px;
		position: relative;

		.delete {
			position: absolute;
			top: 0;
			right: 0;
			padding: 5px;
			background: rgba(0,0,0,.5);
		}
	}
}
.customLocationThumbnails {
	display: flex;
	flex-wrap: wrap;

	.customLocationThumbnail {
		width: 100px;
		height: 100px;
		overflow: hidden;
		margin: 5px;
	}
}

.addInstanceForm {
	display:none;
	background: rgba(0,0,0,.75);
	position: fixed;
	top: 100px;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;

	.content {
		width: 75%;
		height: 80vh;
		overflow: auto;
		background: #fff;
		margin: 25px auto 0;
		padding: 25px;

		h2 { margin: 0; }
	}
}




.svg-map-container {
	 //width: 1200px;
	 position: relative;
	 margin: 0 auto;

}






html.mapview {
	height: 100%;
	max-height: 100%;
	body {
		height: 100%;
		max-height: 100%;

		.map-container {
			height: 100%;
			overflow: hidden;
		}
	}
}

.inactive {
	opacity: .5;
}


.list-group-item {
	cursor: move;
	padding: 5px;
	&.duplicate {
		background: #f2dede;
		border: 1px solid #ebccd1;
		cursor: not-allowed;

		.duplicate-message {
			font-size: 13px
		}
	}
}



.inspiration-landing-text {
	font-family: 'proximaregular';
	padding: 20px 5px 0 5px;
	font-size: 1.7rem;
}
.admin-dashboard-item {
	min-height: 115px;
	background: #fff;
	padding: 15px;
	width: calc(50% - 10px);
	margin-right: 10px;
	margin-bottom: 15px;
	border-radius: 5px;
	cursor: pointer;
	border-left: 5px solid transparent;
	transition: 0.3s ease all;
	&:hover {
		border-left: 5px solid #e98d28;
	}
	 h2, p {
	 	margin: 0;
	 }
	 h2 { margin-bottom: 10px; }
}


.admin-sidenav-wrapper {
	padding: 10px 0;
	background: #fff;
}

.admin-sidenav {
	padding: 0;
	margin: 0;

	li {
		background: #fff;
		display: inline-block;
		padding: 5px 10px;
		margin-right: 10px;
		border-radius: 3px;
		transition: 0.3s ease all;
		&:hover,
		&.active {
			background: #e98d28;
			color: #fff;
			cursor: pointer;
			a {
				color: #fff;
			}
		}
		a:hover {
			color: #fff; 
			text-decoration: none;

		}
	}
}


.adminGridView {
	margin: 0 0 50px 0;
	padding: 0;
	list-style: none;
	li {
		display: inline-block;
		width: calc(100% / 6);
		position: relative;
		height: 190px;
		border: 5px solid #fff;
		cursor: move;
		img {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
		}
		h4 {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			text-align: center;
			color: #fff;
			margin: 0;
			padding: 10px 0 5px;
			background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,.75)); /* Standard syntax (must be last) */
		}
		.actions {
			width: 100%;
			text-align: right;
			position: absolute;
			font-size: 2rem;
			z-index: 25;
			padding: 5px;
			background: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,.75)); /* Standard syntax (must be last) */
			a {
				color: #fff;
				i {
					text-shadow: 0 0 10px rgba(0,0,0,0.5);
				}
			}
		}
	}
}


.admin-list-category-dropdown {
	list-style: none;
	margin: 0 0 25px 0;
	padding: 5px;
	background: #fff;
	li {
		cursor: pointer;
		padding: 5px;

		border-radius: 3px;
		margin-right: 10px;
		display: inline-block;
		&:hover {
			background: #f6f1eb;
		}
	}
}


#slider {
    position: absolute;
    top: 24px;
}

progress {
    margin-top: 21px;
}

progress, #slider {
    width: 300px;
}

progress:focus,
#slider:focus {
    outline: none;
}


progress[value] {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    
    border: none;
    height: 5px;

    /* For IE10 */
    color: #336699;
}


progress[value]::-webkit-progress-bar {
    background-color: #eee;
}


progress[value]::-webkit-progress-value {
    background-color: #3c7dd1;
}

progress[value]::-moz-progress-bar {
    background-color: #3c7dd1;
}

input[type="range"] {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -moz-apperance: none !important;
    /* height: 4px; */
    background: none;
    outline: none !important;
    border: none;
    margin: 0;
    padding: 0;
}

input[type="range"]:focus {
    outline: none;
    border: none;
}

input[type="range"]::-moz-range-track {
    border: none;
    background: transparent;
    outline: none;
}

input[type="range"]::-ms-track {
    border: inherit;
    color: transparent; /* don't drawn vertical reference line */
    background: transparent;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    //background-color: #3c7dd1;
    background-color: #fff;
    height: 15px;
    width: 15px;
    border: 1px solid #ddd;
    border-radius: 50%;
}

input[type="range"]::-moz-range-thumb {
    -moz-appearance: none;
    background: #3c7dd1;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: none;
}

input[type="range"]::-ms-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 0;
    background: blue;
}

input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
  background: transparent;
}

input[type="range"]::-ms-tooltip {
  display: none;
}

#button {
	height: 45px;
    width: 45px;
    border: 1px solid #eee;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}



@import "tablet.scss";
