slick.navigation {
	&.slick-slider {
		margin: 0 auto;
		width: calc(100% - 70px);
	}
}






slick.dealSlider {
	width: calc(100% - 70px);
	margin: 0 auto;
	.slick-prev,
	.slick-next {
		height: 65px;
		background-color: transparent;
		background-repeat: no-repeat;
		background-position: center;
	}
	.slick-prev {
		background-image: url(/img/new-arrow-left.svg);
	}
	.slick-next {
		background-image: url(/img/new-arrow-right.svg);
	}	
	.slick-slide {
		height: 65px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 0;
		img { margin-right: 10px; }
		a { color: #fff; }
	}

}







slick.location.slick-slider {
	width: 100%;
	margin-bottom: 0;
	.slick-prev,
	.slick-next {
		background-color: transparent;
		background-repeat: no-repeat;
		background-position: center;		
	}
	.slick-prev {
		left: 35px;
		background-image: url(/img/carousel-prev-orange.svg);
		//background-image: url(/img/new-arrow-left.svg);
	}
	.slick-next {
		right: 35px !important;
		background-image: url(/img/carousel-next-orange.svg);
		//background-image: url(/img/new-arrow-right.svg);
	}
	.slick-slide {
		border: 0;
	}
}


slick.videoSlider {}







.slick-prev,
.slick-next {
	background-color: rgba(255,255,255,1);
	height: 185px;
	width: 35px;
	margin-top: 0;
	transition: 0.3s ease all;
	&:hover {
		background-color: rgba(255,255,255,1);
	}
}
.slick-prev {
	z-index: 40;
	background-image: url(/img/carousel-prev.svg);
	background-repeat: no-repeat;
	background-position: center;
	&:hover {
		background-image: url(/img/carousel-prev.svg);
		background-repeat: no-repeat;
		background-position: center;
	}
}
.slick-next {
	right: 0 !important;
	background-image: url(/img/carousel-next.svg);
	background-repeat: no-repeat;
	background-position: center;	
}
.slick-prev:before {
	content: '';
}
[dir='rtl'] .slick-prev:before {
	content: '';
}
.slick-slide {
	position: relative;
	height: 185px;
	float: left;
	border-left: 1px solid rgba(255,255,255,0.7);
	border-bottom: 1px solid rgba(255,255,255,0.7);
	border-top: 1px solid rgba(255,255,255,0.7);
	background-size: cover !important;
	color: #fff;
	font-weight: 600;
	text-transform: uppercase;
	font-size: 1.5rem;
	cursor: pointer;

	img {
		width: 100%;
	}
	.overlay {
		background: rgba(0,0,0,0.5);
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		display: flex !important;
		align-items: center;
		justify-content: center;
		transition: 0.3s ease all;
		padding: 35px;

		&:hover {
			color: orange;

			text-shadow: 0 0 5px rgba(0,0,0,0.9);
			background: rgba(0,0,0,0.10);
		}

		p {
			text-align: center;
			a {
				color: #fff;
			}
		}

	}
}

.inspiration-slider {
	.videoWrapper {
		margin-top: 0;
	}
}







.location-slider-prev,
.location-slider-next {
	display: inline-block;
	position: absolute;

	top: 40%;
	z-index: 20;
	color: #fff;

}
.location-slider-prev {
	left: 10px;
}
.location-slider-next {
	right: 10px;
}


slick.tourLocationSlider.slick-slider {
	margin-bottom: 0;

	.slick-slide	{
		height: auto !important;

	}
}



