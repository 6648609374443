.button {
	padding: 15px 30px;
	color: #fff;
	width: 215px;
	text-transform: uppercase;
	border: 0;
	transition: 0.3s ease all;

	&:hover {
		text-decoration: none;
		color: #fff;
	}
	&:focus {
		outline: none;
	}
}


.button-medium {
	width: 100px;
	padding: 10px;
	background: #3c7dd1;
	&:hover {
		background: #e98d28;
	}
	
}
.button-small {
	width: 75px;
	padding: 5px 10px;
}

.button-block {
	width: 100%;
}

.button-customize {
	width: auto;
}


.button-orange {
	background: #e98d28;
}

.button-default {	
	background: #3c7dd1;

	&[disabled] {
		background: #bbb;
		&:hover {
			background: #bbb;
		}
	}


	&:hover {
		background: #e98d28;
	}

	&.expand:hover {
		width: 245px;
	}

}

.button-secondary {
	background: #f6f1eb;
	color: #240f0e;
	border: 1px solid #240f0e;
	&:hover {
		background: #e98d28;
		border: 1px solid #e98d28;
	}
}
.noborder {
	border: none;
	&.active {
		background: #e98d28;
		color: #fff;	
	}
	&:hover {
		border: none;
	}
}

.mapSubNav {
	border: 1px solid transparent;
	background: #f6f1eb;
	color: #240f0e;
	&:hover {
		color: #240f0e;
		background: #f6f1eb;
		border: 1px solid #ccc;
	}	
	&.active {
		background: #e98d28;
		color: #fff;	
	}	
}

.pointer {
	cursor: pointer;
}

.customJourneyButton:hover {
	background: #bbb;
}










