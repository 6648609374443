@font-face {
    font-family: 'proximaregular';
    src: url('../fonts/proxima/ProximaNova-Regular.eot');
    src: url('../fonts/proxima/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/ProximaNova-Regular.woff') format('woff'),
         url('../fonts/proxima/ProximaNova-Regular.ttf') format('truetype'),
         url('../fonts/proxima/ProximaNova-Regular.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proximabold';
    src: url('../fonts/proxima/ProximaNova-Bold.eot');
    src: url('../fonts/proxima/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/ProximaNova-Bold.woff') format('woff'),
         url('../fonts/proxima/ProximaNova-Bold.ttf') format('truetype'),
         url('../fonts/proxima/ProximaNova-Bold.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'proximalight';
    src: url('../fonts/proxima/ProximaNovaLight-Webfont.eot');
    src: url('../fonts/proxima/ProximaNovaLight-Webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/ProximaNovaLight-Webfont.woff') format('woff'),
         url('../fonts/proxima/ProximaNovaLight-Webfont.ttf') format('truetype'),
         url('../fonts/proxima/ProximaNovaLight-Webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proximaregularitalic';
    src: url('../fonts/proxima/ProximaNova-RegularItalic.eot');
    src: url('../fonts/proxima/ProximaNova-RegularItalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proxima/ProximaNova-RegularItalic.woff') format('woff'),
         url('../fonts/proxima/ProximaNova-RegularItalic.ttf') format('truetype'),
         url('../fonts/proxima/ProximaNova-RegularItalic.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'intheknow';
    src: url('../fonts/intheknow/InTheKnow-Webfont.eot');
    src: url('../fonts/intheknow/InTheKnow-Webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/intheknow/InTheKnow-Webfont.woff') format('woff'),
         url('../fonts/intheknow/InTheKnow-Webfont.ttf') format('truetype'),
         url('../fonts/intheknow/InTheKnow-Webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'hannahnarrow';
    src: url('../fonts/hannah-narrow/ywft-hannah-narrow-webfont.eot');
    src: url('../fonts/hannah-narrow/ywft-hannah-narrow-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/hannah-narrow/ywft-hannah-narrow-webfont.woff') format('woff'),
         url('../fonts/hannah-narrow/ywft-hannah-narrow-webfont.ttf') format('truetype'),
         url('../fonts/hannah-narrow/ywft-hannah-narrow-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/open-sans-v14-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
       url('../fonts/open-sans-v14-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v14-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v14-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v14-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v14-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('../fonts/open-sans-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v14-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/open-sans-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('../fonts/open-sans-v14-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v14-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v14-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v14-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v14-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}