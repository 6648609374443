
.map-left-panel {

	width: 350px;
	height: 100%;
	float: left;
	background: #fff;
	position: relative;

	overflow: hidden;

	&.active {
		transform: translateX(0);
	}

	.head {
		padding: 0 20px;
		border-bottom: 1px solid #eee;
		position: relative;

		p { font-size: 1.25rem; }
		p,h4 { margin: 0; }
		h3 { margin: 0 0 10px 0; }
		h4, p { font-family: 'proximaregular'; }

		.title {
			padding: 20px 0;

			small { font-size: 65%; }
		}
		.refine-search {
			position: absolute;
			top: 10px;
			right: 10px;
		}


		&.celebrity {
			.title {
				border-bottom: 1px solid #e4e1df;
			}
			.celebrity-info {
				padding: 20px 0;
				.celebrity-image {
					float: left;
					width: 90px;
					img { width: 100%; }
				}
				.celebrity-description {
					float: left;
					padding: 15px;
					width: 200px;
				}
			}
		}
		&.mapCategory {}

	}

	.foot {
		position: absolute;
		left: 0;
		bottom: 0;
		height: 50px;
		width: 100%;
		background: #ffffff;
		padding: 10px;
		z-index: 45;

		ul {
			padding:0;
			margin: 0;
			list-style: none;
			
			display: flex;
			align-items: center;
			justify-content: center;
			&:hover {
				cursor: pointer;
			}
			li {
				background: none !important;
				padding: 0 10px;
				svg {
					path {
						transition: 0.3s ease all;
						fill: #736564;	
					}
					
				}
				

				&:hover {
					cursor: pointer;
					svg {
						path {
							fill: orange;
						}
					}
				}
			}
		}

	}


	.results-list {

		overflow-y: scroll;
		
		ul {
			padding: 0;
			margin: 0;
			list-style: none;

			&.hasShareControls {
				//padding-bottom: 50px;
			}


			li {
				transition: 0.3s ease all;
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				&:nth-child(even) {
					background: #eee;
				}
				&:hover, &.active {
					color: #fff;
					background: #2a73d2;
					cursor: pointer;
					.result-thumb,
					.result-index {
						color: #fff;
					}
					.result-add,
					.result-remove {
						display: block;
					}
				}

				.result-thumb {
					width: 75px;
					height: 75px;
					background: #fff;
				}
				.result-index {
					width: 40px;
					padding: 10px;
					font-family: 'proximaregular';
					font-weight: bold;
				}
				.result-name {
					padding: 10px 20px 10px 0;
					width: calc(100% - 170px);
					min-height: 75px;
				}
				.result-add,
				.result-remove {
					//transition: 0.3s ease all;
					display: none;
					color: #2a73d2;
					background: #fff;
					width: 35px;
					height: 35px;
					line-height: 130%;
					text-align: center;
					margin-top: 20px;
					border-radius: 50%;
					font-size: 3rem;
					

					&:hover {
						transform: scale(1.15);
					}
				}				
				.result-remove {
					line-height: 115%;
				}
			}
		}
	}
}




.arrow-left {
	width: 0; 
	height: 0; 
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent; 
	border-right:10px solid #fff; 
	position: absolute;
	top: 25px;
	right: 0;
	display: none;
	&.active {
		display: block;
	}
}


.mapElement {
	transition: 0.3s ease all;
	width: calc(100% - 350px);
	//height: 100%;
}



.tourOverviewPanel {
	cursor: pointer;
	position: absolute;
	top: 79px;
	right: -80px;
	width: 190px;
	background: #eee;
	color: #2d2d2d;
	transform: rotate(270deg);
	
	&.active {
		background: #3c7dd1;
		color: #fff;
	}
}


#player {
	display: none;
}
#customPlayer {
	margin-top: 15px;
	padding: 10px 15px;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	align-items: center;
	justify-content: space-between;
	position: relaitve;
}
#customPlayerButton {
	cursor: pointer;
	//position: absolute;
	//left: 48px;
	background: #fff;
	width: 50px;
	height: 50px;
	margin: 0 auto;
	text-align: center;
	border: 1px solid #eee;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #2a73d2;
}

#customPlayerButton .fa-play { margin-left: 5px; }

#customPlayer #currentTime {
	width: 50px;
	text-align: center;
}
#customPlayer #progress-outer {
	width: calc(100% - 100px);
	height: 7px;
	overflow: hidden;
	background: #eee;
}
#customPlayer #progress-inner {
	width: 48px;
	position: relative;
	height: 100%;
	background: #2a73d2;
}
#customPlayer #duration {
	width: 50px;
	text-align: center;
}


progress {
	width: calc(100% - 100px);
	background: #eee;
	height: 7px;
}
progress[value] {
	appearance: none;
}
progress[value]::-webkit-progress-bar {
  background-color: #eee;
}
progress[value]::-webkit-progress-value {
	background: #2a73d2;
}


/*#customPlayer {
	margin-top: 15px;
	padding: 10px 0;
	display: flex;

	align-items: center;
	justify-content: space-between;
	position: relaitve;
}
#customPlayerButton {
	cursor: pointer;
	position: absolute;
	left: calc(50% - 25px);
	background: #fff;
	width: 50px;
	height: 50px;
	margin: 0 auto;
	text-align: center;
	border: 1px solid #eee;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #2a73d2;
}

#customPlayerButton .fa-play { margin-left: 5px; }

#customPlayer #currentTime {
	width: 50px;
	text-align: center;
}
#customPlayer #progress-outer {
	width: calc(100% - 100px); height: 7px; background: #eee;
}
#customPlayer #progress-inner {
	width: 0;
	height: 100%;
	background: #2a73d2;
}
#customPlayer #duration {
	width: 50px;
	text-align: center;
}

*/

.distance-time {
	padding: 15px 25px;
	border-bottom: 1px solid #eee;
	p { margin: 0; }
	p:nth-child(1) { margin: 0 0 10px 0; }

}

.tourDialog,
.tourOverviewDialog {
	position: fixed;
	top: 99px;
	width: 100%;
	height: calc(100% - 183px);
	overflow-y: auto;
	width: 425px;
	background: #fff;
	transform: translateX(-75px);
	transition: 0.3s ease all;
	//box-shadow: 0 0 50px rgba(0,0,0,.35);
	
	&.active {
		transform: translateX(350px);
		box-shadow: 0 0 50px rgba(0,0,0,.35);
	}
	.header {
		min-height: 185px;
		position: relative;

		p.closeButton {
			position: absolute;
			top: 0;
			left: 10px;
			width: 25px;
			height: 25px;
			color: #fff;
			z-index: 3;
			font-size: 2.25em;
		}

		h3.title {
			margin: 25px 0;
			position: absolute;
			top: 20px;
			left: 0;
			width: 100%;
			text-align: center;
			color: #fff;
			padding: 0 20px;
			z-index: 3;
			text-shadow: 0 0 8px #000;
		}

	}

	.content {
		padding: 15px;
		ul {
			margin: 0 0 15px 0;
			padding: 5px 20px;
		}
		h3,h4 {
			strong {
				font-family: 'proximabold';
			}
		}

		.currentLocation {
			margin-top: 35px;
			h4 {
				font-size: 16px;
			}
			.listing {
				display: flex;
				border-bottom: 1px solid #eee;
				border-right: 1px solid #eee;
				cursor: pointer;

				div:nth-child(1) {
					margin-right: 15px;
				}
				div:nth-child(2) {
					p {
						margin:0;
						color: #bbb;
						.fa {
							color: #2a73d2;
						}
						span {
							font-size: 12px;
						}
					}
					h5 {
						 margin:2px 0 5px;
						 font-size:16px;
					}
					
				}
			}
		}
	}

}



.mapDialog,
.mapDialogCustomContent,
.airportDialog {

	transition: 0.3s ease all;
	position: fixed;
	top: 100px;
	left: 350px;
	bottom: 82px;
	right: 0;
	z-index: 200;
	background: rgba(0,0,0,0.6);
	//background: transparent;



	.closeBtn {
		
		position: absolute;
		right: 0;
		top: 0;
		z-index: 55;
		color: #000;
		width: 35px;
		height: 35px;
		border: 1px solid #fff;
		border-radius: 50%;
		text-align: center;
		padding-top: 1px;
		font-size: 24px;
		transition: 0.3s ease all;
		&:hover {
			text-decoration: none;
			background: rgba(0,0,0,0.4);
		}
	}

	.content {
		position: relative;
		width: calc(100% - 60px);
		height: calc(100% - 60px);
		margin: 30px;
		background: #fff;
		overflow-y: scroll;


		.heading {
			width: 75%;
			img {
				transition-delay: 1s;
				.hidden {
					display: none;
				}
			}
		}
		.body {
			padding: 20px;
			width: 100%;
			

			&.forTesting {
				height: 500px;
			}

			ul {
				list-style: none;
				margin: 0 0 10px 0;
				padding: 0;

				li.cuisine-types,
				li.store-types {
					span:after {
						content: ',';
					}
					span:last-child:after {
						content: '';
					}
				}
				li.lodging-features,
				li.nightlife-features {
					display: inline-block;
					width: 49%;
				}
			}
		}
		.footer {
			padding: 25px 25px 10px 25px;
			display: flex;
			justify-content: space-between;
			div {

				text-align: center;

				h5 {
					color: #e98d28;
					margin: 0;
				}
				p {
					font-size: 1.25rem;
					font-weight: 400;
				}
			}
		}
		.deals {
			padding: 10px 0;
			background: #240f0e;
			color: #fff;
		}
	}
}


.mapDialog {
	

	.content-wrapper {
		display: flex;
		flex-wrap: wrap;
		border-bottom: 1px solid #f3f3f3;

		.left-column {
			width: 50%;
			&.fullWidth {
				width: 100%;
			}


			.add-back {
				background: #eee;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 6px 10px;

				.fa {
					color: #3c7dd1;
					font-size: 17px;
				}

				button {
					border-radius: 0;
					font-size: 1.2rem;
					padding: 8px 10px;
					width: auto;
				}
				.add-tip {
					p {
						margin: 5px 9px;
					}
					a {
						cursor: pointer;
					}
				}
			}
			.location-information {
				padding: 10px 35px;
				opacity: 1;
				transition: 0.3s ease all;
				&.loading {
					opacity: 0;
				}
			}
			.location-meta {
				padding:0;
				margin-top:50px;
				list-style: none;
				opacity: 1;
				&.loading {
					opacity: 0;
				}


				li {
					display: flex;
					align-items: center;
					margin-bottom: 20px;

					div:nth-child(1) {
						width: 35px;
						.fa { color: #666666; }
					}
					div:nth-child(2) {
						width: calc(100% - 35px);
						h5,p { margin: 0; }
						h5 { font-family: 'proximabold'; }
					}
				}
			}

		}
		.right-column {
			width: 50%;

			.stage {
				width: 100%;
				height: 400px;
				transition: 0.2s ease all;

				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				opacity: 1;

				

				.animate-show.ng-hide-add, .animate-show.ng-hide-remove {
					transition: all linear 0.3s;
					display: block !important;
				}
				.animate-show.ng-hide-add.ng-hide-add-active, .animate-show.ng-hide-remove {
					opacity: 0;
				}
				.animate-show.ng-hide-add, .animate-show.ng-hide-remove.ng-hide-remove-active {
					opacity: 1;
				}
			}

			.stage-thumbs {
				display: flex;
				flex-wrap: wrap;
				padding: 10px 10px 10px 0;
				height: 175px;
				overflow-y: auto;

				div {
					width: calc(20% - 10px);
					margin: 5px;
					cursor: pointer;

					img {
						width: 100%;
						&.active {
							border: 2px solid #2d2d2d;
						}

					}
				}
			}
		}

	}



	.major-category-information,
	.minor-category-information,

	.location-description {
		padding: 15px 50px;
		h3,h4,h5,strong {
			font-family: 'proximabold';
		}

		h3,h5,p,ul {
			font-size: 16px;

		}

		.mapoverlay {
			display: none;
		}

		table { display: none; }

		.info-wrapper {
			margin-bottom: 20px;
		}

		.info-list {
			padding: 0 20px;
			font-size: 15px;
			li {
				padding: 7px 0;
			}
		}

		.open-for {
			span:not(:last-child):after {
				content: ',';
			}
		}


		.feature-list {
			li {
				display: inline-block;
				&:after {
					content: '*';
				}
			}
		}
	}

}

.airportDialog {
	.content {
		height: 625px;
		.body {
			height: 175px;
		}
	}
}




.map-container {
	width: 100%;
	height: 100%;
}

/*.isMapView {
	height: 100%;
	.map-container {

		height: calc(100% - 75px);
	}
}
*/

.slick-slide.map {
	height: 250px;
}



footer.mapFooter {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10;
	padding: 10px;
	.form-error {
		top: -30px !important;
	}
	
	form {
		margin-bottom: 5px;

	}
}


	.map-controls {
		position:absolute;
		top:150px;
		right:25px;
		z-index:175;
		width:72px;
		cursor: default;

	}
	.map-pan {
		box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.15);
		width: 100%;
		height: 72px;
		border-radius: 50%;
		background: #f0eeee;
		background: #fff;
		position: relative;

	}
	.map-pan-up,
	.map-pan-down {
		position: absolute;
		text-align: center;
		width: 100%;
		cursor: pointer;
	}
	.map-pan-up { top: 5px; left: 0; }
	.map-pan-down { bottom: 5px; left: 0; }

	.map-pan-left,
	.map-pan-right {
		position: absolute;
		cursor: pointer;
	}
	.map-pan-left { left: 8px; top: 26px; }
	.map-pan-right { right: 8px; top: 26px; }




	.map-zoom {
		background: #f0eeee;
		background: #fff;
		margin: 25px auto;
		width: 30px;
		border-radius: 2px;
		box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.15);
	}
	.map-zoom-in,
	.map-zoom-out {
		width: 100%;
		font-size: 2rem;
		text-align: center;
		height: 30px;
		border-top: 1px solid #e1dfdf;
		cursor: pointer;
	}
	.map-neighborhoods,
	.map-transportation,
	.map-airport {
		position: relative;
		cursor: pointer;
		text-align: center;
		width: 33px;
		height: 33px;
		margin: 0 auto 10px;
		background-color: #fff;
	}

	.map-neighborhoods {
		background-image :url(/img/map-control-neighborhoods.png);
		background-repeat: no-repeat;
		background-position: 0 0;
		&:hover,
		&.active {
			background-position: -34px 0;
		}
	}
	html.touchevents .map-neighborhoods {
		background-image :url(/img/map-control-neighborhoods.png);
		background-repeat: no-repeat;
		background-position: 0 0;		
		&:hover, &.active {
			background-position: 0 0;
		}
	}

	.map-transportation {
		background-color: #fff;
		background-image: url(/img/map-control-transportation.png);
		background-repeat: no-repeat;
		background-position: 1px 0;
		&:hover,
		&.active {
			background-position: -34px 0;
		}
	}
	html.touchevents .map-transportation {
		background-color: #fff;
		background-image: url(/img/map-control-transportation.png);
		background-repeat: no-repeat;
		background-position: 1px 0;
		&:hover,
		&.active {
			background-position: 0 0;
		}
	}

	.map-airport {
		background-color: #fff;
		background-image: url(/img/map-control-airport.png);
		background-repeat: no-repeat;
		background-position: 1px 0;
		&:hover {
			background-position: -34px 0;
		}
	}
	html.touchevents .map-airport {
		background-color: #fff;
		background-image: url(/img/map-control-airport.png);
		background-repeat: no-repeat;
		background-position: 1px 0;
		&:hover {
			background-position: 0 0;
		}
	}





	
	.tooltip-view-neighborhoods,
	.tooltip-view-streetcars,
	.tooltip-view-airport {
		transition: 0.3s ease all;
		position: absolute;
		right: 45px;
		width: 130px;
		display: none;
		top: 3px;
		font-size: 1rem;
		font-family: 'proximabold';
		background: #fff;
		padding: 5px;
		&.active {
			display: block;
		}
		.triangle-right {
			position: absolute;
			top: 7px;
			right: -6px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 5px 0 5px 8.7px;
			border-color: transparent transparent transparent #ffffff;
		}
		p { margin: 0; }
	}






.sLabel {
	opacity: 0;
}
.showIcon {
	opacity: 1;
}
.hideIcon {
	opacity: 0;
}

.hideIcon.minorStop,
.hideIcon.majorStop {
	opacity: 0;
}
.showIcon.minorStop,
.showIcon.majorStop {
	opacity: 1;
}



.airport-list {
	padding: 0;
	margin: 0;
	text-align: center;
	li {
		padding: 0 3px;
		display: inline-block;
		a {
			color: #fff; text-decoration: underline;
			&:hover {
				color: #e98d28;
			}
		}
	}
}






.refine-search-button {
	display: inline-block;
	font-size: 11px;
	.fa {
		vertical-align: middle;
	}
	&:hover {
		color: #e98d28;
		cursor: pointer;
	}
}


.checkbox-filters {
	padding: 10px 0;
	font-family: 'proximaregular';
	font-size: 1.2rem;
	border-top: 1px solid #eee;
	.list {
		list-style: none;
		padding: 0;
		margin: 0;
		li { 
			display: inline-block;
			width: 49%;
			margin: 0;
			img {
				width: 25px;
				margin: 0 5px;
				padding-bottom: 5px;
			}
			label { margin-bottom: 0; }
			label.active {
				color: #b8854e;
			}
			label.disabled {
				color: #dddddd;
			}
		}
	}
}
#dMap {
	width: calc(100% - 350px);
	height: 100%;
	float: right;
}

.infobox {
	width: 165px;
	height: 165px;
	background: rgba(36,15,14, .75);
	position: relative;
	color: #fff;
	padding: 5px;
	p {
		margin: 0;
		padding: 5px;
		position: absolute;
		bottom: 5px;
		left: 5px;
		background: rgba(36,15,14,.75);
		width: calc(100% - 10px);
	}
	img { width: 100%;}
	.triangle-bottom {
		position: absolute;
		left: 65px;
		bottom: -20px;
		width: 0px;
		height: 0px;
		border-style: solid;
		border-width: 20px 12.5px 0 12.5px;
		border-color: rgba(35,15,14, .75) transparent transparent transparent;
	}
	.infobox-marker {
		position: absolute;
		top: 0;
		left: 0;
		width: 35px;
		height: 35px;
	}
}

.tt-add,
.tt-remove {
	font-family: 'proximabold';
	left: 90%;
	top: 65%;
	background: #fff;
	color: #3474ce;
	height: 30px;
	padding-left: 10px;
	padding-top:3px;
	box-shadow: 0 0 5px rgba(0,0,0,0.25);
}

.tt-add { width: 135px; }
.tt-remove { width:170px; }

.tt-share {
	font-family: 'proximabold';
	background: #250c0c;
	color: #fff;
	height: 25px;
	padding-top: 1px;
	text-align: center;
	&.facebook {
		width: 170px;
	}
	&.twitter {
		width: 150px;
	}
}



.tooltip-inner {
	background: #fff;
	border-radius: 0;
	font-family: 'proximabold';
	padding: 5px;
}
.tooltip-arrow {
	border-width: 3px 3px 0;
    border-right-color: #fff;

}




.noIconImage {
	.infobox-marker {
		display: none;
	}
}