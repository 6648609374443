.panel-neighborhood,
.panel-inspiration {
	width: 100%;
	position: fixed;
	top: -300px;
	z-index: 500;
	left: 0;
	height: 185px;
	overflow: hidden;
	background: rgba(255,255,255,0.5);
	transition: 0.3s ease all;

	&.active {
		top: 96px;
	}
}

.panel-maps {
	position: fixed;
	top: -175px;	
	left: 0;
	z-index: 500;
	width: 100%;
	background: #f5f0ea;
	padding: 0;
	transition: 0.3s ease all;
	&.active {
		top: 96px;
		

	}
	.map-panel-items {
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
		align-items: flex-end;
		list-style-type: none;
		li {
			vertical-align: bottom;
			padding: 5px;
			width: 20%;
			height:95px;
			border-right: 1px solid #dcd8d3;
			text-align: center;
			cursor: pointer;
			position: relative;
			transition: 0.3s ease all;

			.wa-click-to-close {
				padding: 8px;
			}

			p {
				margin: 10px 0 0;
				//font-weight: 600;
			}

			.child-panel {
				display: none;
				position: fixed;
				left: 0;
				top: 191px;
				width: 100%;
				background: #fff;
				cursor: default;

				&.active {
					display: block;
				}

				 .child-panel-close {
				 	 position: absolute;
				 	 display: flex;
				 	 justify-content: center;
				 	 align-items: center;
				 	 right: 15px;
				 	 top: 10px;
				 	 cursor: pointer;
				 	 color: #2d2d2d;
				 	 span { margin-right: 5px; }
				 }

				&.custom {
					.content {
						color: #000;
						background: #fff;
						padding: 35px 100px;
						h3 { margin: 0; }
						h4 {
							 font-family: 'proximabold';
							 font-size: 20px;
						}
						.koo {
							margin: 5px 10px;
							&:nth-of-type(7) {
								margin-left: 25px;
							}
						}
						form {
							input.form-control {
								padding: 0;
								height: auto;
							}
						}
						.subMenuBtn {
							padding: 0;
						}
					}
				}
				&.stay,
				&.eat,
				&.drink,
				&.attractions,
				&.shopping {
					.content {
						text-align: left;
						margin: 0 auto;
						background: #fff;
						padding: 25px;
						color: #000;
						h3 { margin: 0; }

						label {
							color: #000;
							display: block;
						}
						input::-moz-placeholder, 
						input::-webkit-input-placeholder {
							color: #aaa; 
							opacity: 1 !important;
						}
						input[type=text] {
							color: #aaaaaa;
							width: 100%;
							padding: 6px;
							border: 1px solid #ccc;
							outline: none;
						}
						select {
							outline: none;
							-webkit-appearance: none;
							-moz-appearance: none;
							border-radius: 0;
							width: 100%;
							border: 1px solid #ccc;
							background: #fff;
							color: #aaa;
							padding: 6px 10px;
							background: url(/img/select-arrow.svg) no-repeat 98% center;
						}
						button {
							padding: 10px;
							min-width: 125px;
						}
						a { color: #000; font-weight: bold;  }
						.whenBtn {
							margin-bottom: 15px;
							margin-right: 10px;
							padding: 7px 10px;
							color: #ee9a3f;
							font-size: 1.2rem;
							border: 1px solid #ee9a3f;
							background: none;
							&:hover, &.active {
								background: #ee9a3f;
								color: #fff;
							}
							&:focus {
								outline: none;
							}
						}
						.multipleSelectList {
							padding: 10px;
							height: 250px;
							width: 100%;
							overflow: scroll;
							background: #fafafa;
							position: absolute;
							z-index: 45;
							display: none;
							&.showing {
								display: block;
							}
							ul {
								list-style: none;
								margin: 0;
								padding: 0;
								li {
									width: 100%;
									border-right: none;
									border-left: none;
									height: auto;
									text-align: left;
								}
							}
						}						
					}
				}
			}
		}
		li:hover, li.active {
			background: #fff;
			color: #e98d28;
			path, rect {
				fill: #e98d28;
				stroke: #e98d28;
			}
		}
		li:first-child {
			border-left: 1px solid #dcd8d3;
		}

		li.custom-tag {
			width: auto;
			border-left: 0;
			border-right: 0;
			height: auto;
			display: inline-block;
			background: #efefef;
			padding:10px 35px;
			min-width: 75px;
			margin: 5px;
			//font-family: 'proximabold';
			color: #2d2d2d;
			text-transform: uppercase;
			&.active {
				background: #e98d28;
				color: #fff;
			}
		}
	}
	.control {
		box-sizing: border-box;
		position: absolute;
		top: 0;
		height: 175px;
		width: 75px;
		padding: 65px 25px;
		cursor: pointer;
		
		background: #fff;
		&.prev {
			left: 0;
		}
		&.next {
			right: 0;
		}
	}
}



.hasSelectArrow {
	background: url(/img/select-arrow.svg) no-repeat 98% center;
	border: 1px solid #ccc;
	padding: 6px 10px;
}
.panel-inspiration {
	.slideContainer {
		div.overlay {
			background: rgba(0,0,0,0.5);
			font-size: 1.5rem;
			&:hover {
				background: rgba(0,0,0,0);
			}
		}
	}
}
.triangle {
	display: none;
	position: absolute;
	left: calc(50% - 10px);
	bottom: -10px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 10px 10px 10px;
	border-color: transparent transparent #2d2d2d transparent;
	&.active {
		display: block;
	}
}


.eat-menu-column-1 {
	width: 20%;
}
.eat-menu-column-2 {
	width: 25%;

}
.eat-menu-column-3 {
	width: 15%;

}
.eat-menu-column-4 {
	width: 443px;

}


.panel-maps .map-panel-items li .wa-click-to-close {
	p { margin: 0; }
	img {
		width: 55px;
	}
}







